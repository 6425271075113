import React, { useEffect } from "react";
import { BASE_URL, INVOICE_BASE } from "../../global";
import { useNavigate, useParams } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { generateNumber } from "../../utils/utils";
import GetSrfData from "./getSrfData";
import ProductDetails, { fetchAddresses } from "./productDetails";
import { createInvoiceGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import EditWrap from "../commonComponent/editWrap";

const gstTypes = [
  { value: 1, label: "State GST" },
  { value: 2, label: "Central Gst" },
  { value: 3, label: "Non Gst" },
];

export default function CreateAndEditInvoice(props) {
  const createInvoiceGuides = createInvoiceGuide();
  const [filterProductList, setFilterProductList] = React.useState([]);
  const [uniqueProductsByRange, setUniqueProductsByRange] = React.useState([]);
  const [productList, setProductList] = React.useState([]);
  const [clientList, setClientList] = React.useState([]);
  const [grandTotal, setGrandTotal] = React.useState(0);
  const userType = localStorage.getItem("type");
  const [defaultReamrk, setDefaultRemark] = React.useState(null);
  const [opTableData, setOpTableData] = React.useState([]);
  const [poDate, setpoDate] = React.useState(null);
  const [poNumber, setpoNumber] = React.useState(null);
  const [srfNumbers, setsrfNumbers] = React.useState([]);
  const params = useParams();
  const [productRows, setproductRows] = React.useState([]);
  const [invoiceNumber1, setInvoiceNumber1] = React.useState(null);
  const [invoiceID, setInvoiceID] = React.useState(null);
  const [allAddress, setAllAddress] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState({});
  const [getAllProducts, setGetAllProducts] = React.useState([]);
  const [gstPercentageOptions, setGstPercentageOptions] = React.useState([]);
  const [getAllSrfsProducts, setGetAllSrfProducts] = React.useState([]);
  const [isMergedProduct , setIsMergedProduct] = React.useState(0)
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const [invoiceObject, updateInvoiceObject] = React.useState({
    subject: "Invoice for Calibration of Equipment",
    challanNo: "",
    challanDate: "",
    ourChallanNo: "",
    ourChallanDate: "",
    productDetails: "",
    totalAmount: "",
    clientId: "",
    quotationNumber: "",
    referenceNumber: "",
    invoiceDate: "",
    // invoiceNumber: "",
    gstType: gstTypes[0],
    companyName: "",
    address: "",
    termsConditions: "",
    conveyanceCharges: "",
    courierCharges: "",
    selectedSRF: [],
    equipmentDelivery: "",
    srfInstrumentIdsArray: [],
    discount: null,
    discountAmount: "",
    GSTPercentage: 18,
  });
  const userName = localStorage.getItem("id");
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    partiallyNumbers: ["Final", 1],
    breakupId: "1",
  });
  const [loading, setLoading] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [paymentMasterID, setpaymentMasterID] = React.useState(0);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const debouncedFetchAddresses = async (addressIds, shippingAddressIds) => {
    setLoading(true);
    const newAddressArray = await fetchAddresses(addressIds, shippingAddressIds);
    setAllAddress(newAddressArray);
    setLoading(false);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const findTotalAmount = () => {
    return productRows?.reduce((total, el) => total + el.amount, 0) || 0;
  }

  const paymentMasterIDFetch = () => {
    axiosWithToken
      .get(BASE_URL + `paymentMaster?_where=(invoiceNumber,eq,${invoiceNumber1})`)
      .then((res) => setpaymentMasterID(res.data[0]?.id))
      .catch((err) => console.log(err));
  };

  const handleSubmit = async (event) => {
    let AllProducts = productRows.map(({ id,name, ...product }) => ({
      ...product,
      type: 2,
      referenceId: invoiceID || invoiceObject.id,
      breakupId: parseFloat(
        state?.breakupId === "Final" ? 1 : state?.breakupId
      ),
    }));
    
    let productTotalAmt = findTotalAmount();

    const rows = {
      clientId: parseFloat(invoiceObject.companyName.id),
      referenceNumber: invoiceObject.referenceNumber,
      challanNo: invoiceObject.challanNo || "",
      challanDate: invoiceObject.challanDate
        ? moment(invoiceObject.challanDate).format("YYYY-MM-DD")
        : null,
      ourChallanNo: invoiceObject.ourChallanNo || "",
      ourChallanDate: invoiceObject.ourChallanDate
        ? moment(invoiceObject.ourChallanDate).format("YYYY-MM-DD")
        : null,
      invoiceNumber: invoiceNumber1 || null,
      invoiceDate: invoiceObject.invoiceDate
        ? moment(invoiceObject.invoiceDate).format("YYYY-MM-DD")
        : null,
      poNumber: poNumber || null,
      quantityMerge: isMergedProduct|| 0,
      poDate: poDate ? moment(poDate).format("YYYY-MM-DD") : null,
      subject: invoiceObject.subject,
      serviceReqNumber: invoiceObject?.selectedSRF?.join(","),
      termsConditions: invoiceObject.termsConditions || defaultReamrk,
      equipmentDelivery: invoiceObject.equipmentDelivery,
      address: invoiceObject?.address || `${allAddress[0]?.id},${allAddress[0]?.shipping?.id}`,
      gstType: invoiceObject.gstType?.value || "",
      conveyanceCharges: invoiceObject.conveyanceCharges || "",
      courierCharges: invoiceObject.courierCharges || null,
      discount: invoiceObject.discount || null,
      gstpercentages: invoiceObject.GSTPercentage || null,
      createdBy: userName,
    };

    const payload = {
      clientId: parseFloat(invoiceObject.companyName.id) || "",
      address: invoiceObject?.address || `${allAddress[0]?.id},${allAddress[0]?.shipping?.id}`,
      totalAmount: grandTotal ? grandTotal : 0,
      gstType: invoiceObject.gstType?.value || "",
      subject: invoiceObject.subject || "",
      sgst:(
        (productTotalAmt *
          (invoiceObject?.GSTPercentage
            ? invoiceObject?.GSTPercentage / 2
            : 9)) /
        100
      ).toFixed(2),
      cgst:(
        (productTotalAmt *
          (invoiceObject?.GSTPercentage
            ? invoiceObject?.GSTPercentage / 2
            : 9)) /
        100
      ).toFixed(2),
      conveyanceCharges: invoiceObject.conveyanceCharges || "",
      invoiceNumber: invoiceNumber1 || null,
      contactNumber: invoiceObject.companyName.contact || "",
      poNumber: poNumber,
      invoiceDate: invoiceObject.invoiceDate
        ? moment(invoiceObject.invoiceDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
    };

    const url = BASE_URL;

    try {
      if (params.invoiceId) {
        const res = await axiosWithToken.patch(
          url + `customInvoice/${invoiceObject.id}`,
          rows
        );

        const sqlQuery = {
          query: `DELETE FROM productDetails WHERE type = 2 AND referenceId = ${
            invoiceObject.id
          } ${
            state?.breakupId != "Final"
              ? `AND breakupId = ${state?.breakupId}`
              : ""
          }`,
        };

        await axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery);
        await axiosWithToken.post(url + "productDetails/bulk", AllProducts);
        await axiosWithToken.patch(url + `paymentMaster/${paymentMasterID}`, payload);

        setTimeout(refresh, 500);
        toast("Invoice edit successfully !");
      } else {
        const res = await axiosWithToken.post(url + "customInvoice", rows);
        var insertedID = res.data.insertId;
        AllProducts = productRows.map(({ id, name, ...product }) => ({
          ...product,
          type: 2,
          referenceId: insertedID,
          breakupId: parseFloat(
            state?.breakupId === "Final" ? 1 : state?.breakupId
          ),
        }));
  
        await Promise.all([
          axiosWithToken.post(url + "paymentMaster", payload),
          axiosWithToken.post(url + "productDetails/bulk", AllProducts),
        ]);

        toast("Invoice created successfully !");
        if (params.quotationId) {
          navigate(
            `/Invoice/editInvoice/${parseFloat(
              invoiceObject.companyName.id
            )}/${invoiceID}`
          );
        } else {
          setTimeout(refresh, 500);
        }
      }
    } catch (err) {
      let trace = new Error().stack;
      if (params.invoiceId) {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, trace ,"Edit Invoice")}><u>Error: {err.message}</u></h6>);
        }
      } else {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, trace ,"Edit Invoice")}><u>Error: {err.message}</u></h6>);
        }
      }
    }
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        let pushArray = [];
        res.data.map((client) => {
          pushArray.push({
            id: client.id,
            label: client.companyName,
            contact: client.contact,
            address: client.address,
            shippingAddress: client?.shippingAddress,
          });
        });
        setClientList(pushArray);
      })
      .catch((err) => {
        console.log(err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getCustomer" ,"Edit Invoice")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const getSRFS = (event) => {
    let url = BASE_URL;
    let data = {
      query: `SELECT serviceReqNumber FROM srfs WHERE clientId =${invoiceObject?.companyName?.id} AND status = 1 AND NOT EXISTS (SELECT 1 FROM customInvoice WHERE customInvoice.serviceReqNumber = srfs.serviceReqNumber)`,
    };
    axiosWithToken
      .post(url + `dynamic`, data)
      .then((res) => {
        let data = res.data;
        let newData = data
          .map((e) => e.serviceReqNumber)
          .filter((value) => value !== null);

        setsrfNumbers(newData);
      })
      .catch((err) => {
        console.log(err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getSRFS" ,"Edit Invoice")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  // api calls
  async function fetchSettings() {
    axiosWithToken.get(BASE_URL + `settings`).then((res) => {
      let remark = null;
      let qtNumber = null;
      res.data.map((setting) => {
        if (setting.keyName === "Invoice Terms") {
          remark = setting.value;
        }
        if (setting.keyName === "Invoice Number") {
          qtNumber = setting.value;
        }
        if (setting.keyName === "gstPercentages") {
          let newKeys = setting.value.split(",");
          const arrayOfObjects = newKeys.map((value) => ({
            value: parseFloat(value),
            label: value,
          }));
          setGstPercentageOptions(arrayOfObjects);
        }
      });
      !params.invoiceId &&
        axiosWithToken
          .get(BASE_URL + "customInvoice?_fields=id,invoiceNumber&_sort=-id&_size=1")
          .then((res) => {
            let latestInvoiceNo = res.data[0]?.invoiceNumber?.slice(res.data[0]?.invoiceNumber?.indexOf("2425") + 4);
            let qt = generateNumber(
              qtNumber,
              `${res.data.length > 0 ? Number(latestInvoiceNo)  + INVOICE_BASE: 1}`
            );
            let newInvoice =  `2425${(res.data.length > 0 ? Number(latestInvoiceNo)  + INVOICE_BASE : 1).toString().padStart(4, '0')}`
            let idIs = res?.data[0]?.id + 1 || 1;
            setInvoiceID(idIs);
            // updateQuoObject("invoiceNumber", qt);
            // setInvoiceNumber1(qt);
            setInvoiceNumber1(newInvoice);
          }).catch((err) => {
            console.log(err);
            if (err.message !== "request_aborted") {
              toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchSettings" ,"Edit Invoice")}><u>Error: {err.message}</u></h6>);
            }
          });
      setDefaultRemark(remark);
    });
  }

  const fetchInvoiceDetails = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `customInvoice/${params.invoiceId}`)
      .then(async (res) => {
        let invoiceData = res.data[0];
        setInvoiceNumber1(invoiceData?.invoiceNumber);
        let newData = {
          ...invoiceObject,
          id: invoiceData?.id,
          productDetails: invoiceData?.productDetails || "",
          totalAmount: invoiceData?.totalAmount || "",
          clientId: invoiceData?.clientId || "",
          challanNo: invoiceData?.challanNo || "",
          challanDate: invoiceData?.challanDate || "",
          ourChallanNo: invoiceData?.ourChallanNo || "",
          ourChallanDate: invoiceData?.ourChallanDate || "",
          invoiceDate: invoiceData?.invoiceDate || "",
          quotationNumber: invoiceData?.quotationNumber || "",
          referenceNumber: invoiceData?.referenceNumber || "",
          equipmentDelivery: invoiceData?.equipmentDelivery || "",
          GSTPercentage: invoiceData?.gstpercentages || null,
          poNumber: invoiceData?.poNumber || "",
          poDate: invoiceData?.poDate || "",
          gstType:
            invoiceData?.gstType &&
            gstTypes?.filter((e) => e.value == invoiceData?.gstType)[0]
              ? gstTypes?.filter((e) => e.value == invoiceData?.gstType)[0]
              : "",
          companyName:
            clientList?.filter((e) => e.id == invoiceData?.clientId)[0] || "",
          address: invoiceData?.address,
          termsConditions: invoiceData?.termsConditions || "",
          conveyanceCharges: invoiceData?.conveyanceCharges || "",
          courierCharges: invoiceData?.courierCharges || "",
          discount: invoiceData?.discount || "",
          selectedSRF: invoiceData?.serviceReqNumber.split(","),
        };
        setTimeout(() => {
          invoiceData && setIsMergedProduct(invoiceData?.quantityMerge);
          invoiceData && setpoNumber(newData?.poNumber);
          invoiceData && setpoDate(newData?.poDate);
          invoiceData && updateInvoiceObject(newData);
          // invoiceData && setGrandTotal(invoiceData?.totalAmount);
        }, 1000);

        let allProducts = [];
        let sqlQuery = {
          query: `SELECT * FROM productDetails WHERE type = 2 AND referenceId = ${newData?.id}`,
        };

        const response = await axiosWithToken.post(
          BASE_URL + `dynamic`,
          sqlQuery
        );
        allProducts = response.data;

        setGetAllProducts(allProducts);

        let sqlQuery1 = {
          query: `SELECT DISTINCT breakupId FROM productDetails WHERE type = 2 AND referenceId = ${newData?.id}`,
        };

        const response1 = await axiosWithToken.post(
          BASE_URL + `dynamic`,
          sqlQuery1
        );
        const breakupIdsArray = response1.data
          .map((item) => item.breakupId)
          ?.filter((id) => id !== 1);

        setState((prevState) => {
          const uniqueNumbers = new Set([
            ...prevState.partiallyNumbers,
            ...breakupIdsArray,
          ]);
          return {
            ...prevState,
            partiallyNumbers: Array.from(uniqueNumbers),
          };
        });
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchInvoiceDetails" ,"Edit Invoice")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const fetchDataAndProcess = async (Id, isMergedProduct) => {
    try {
      let allProducts = [];
      let sqlQuery = {
        query: `SELECT * FROM productDetails WHERE type = 1 AND referenceId = ${Id}`,
      };
  
      const response = await axiosWithToken.post(
        BASE_URL + `dynamic`,
        sqlQuery
      );
      allProducts = response.data;
  
      const processedProducts = isMergedProduct
        ? Object.values(
            allProducts.reduce((acc, obj) => {
              const key = obj.rate + "_" + obj.equipmentId;
              if (acc[key]) {
                acc[key].quantity += obj.quantity;
              } else {
                acc[key] = { ...obj };
              }
              return acc;
            }, {})
          )
        : allProducts;
  
        setproductRows(processedProducts);
   
    } catch (err) {
      console.error("Error fetching and processing data:", err);
      let trace = new Error().stack;
      if (err.message !== "request_aborted") {
        toast.error(<h6 onClick={() => openErrorModal(err.message, trace ,"Edit Invoice")}><u>Error: {err.message}</u></h6>);
      }
    }
  };

  const fetchQutationDetails = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `xjoin?_join=qt.quotation,_j,client.clients&_on1=(qt.clientId,eq,client.id)&_fields=qt.productDetails,qt.id,qt.gstType,qt.gstpercentages,qt.quotationNumber,qt.referenceNumber,qt.date,qt.quantityMerge,qt.enquiryId,qt.enquiryDate,qt.totalAmount,client.companyName,client.contact,qt.address,client.id,qt.srfInstrumentId,qt.subject,qt.termsConditions,qt.conveyanceCharges,qt.courierCharges,qt.discount,qt.quotationValidity,qt.equipmentCollection,qt.equipmentDelivery,qt.clientId&_where=(qt.quotationNumber,like,${params.quotationId})`
      )
      .then(async (res) => {
        let quotationData = res.data[0];
        let newData = {
          ...invoiceObject,
          id: quotationData?.qt_id,
          productDetails: quotationData?.qt_productDetails || "",
          totalAmount: quotationData?.qt_totalAmount || "",
          clientId: quotationData?.qt_clientId || "",
          enquiryId:
            quotationData?.qt_enquiryId && quotationData?.qt_enquiryId != ""
              ? quotationData?.qt_enquiryId
              : "",
          enquiryDate: quotationData?.qt_enquiryDate || "",
          quotationNumber: quotationData?.qt_quotationNumber || "",
          referenceNumber: quotationData?.qt_referenceNumber || "",
          GSTPercentage: quotationData?.qt_gstpercentages || null,
          gstType:
            quotationData?.qt_gstType &&
            gstTypes?.filter((e) => e.value == quotationData?.qt_gstType)[0]
              ? gstTypes?.filter((e) => e.value == quotationData?.qt_gstType)[0]
              : "",
          companyName:
            clientList?.filter((e) => e.id == quotationData?.client_id)[0] ||
            "",
          address: quotationData?.qt_address,
          termsConditions: quotationData?.qt_termsConditions || "",
          conveyanceCharges: quotationData?.qt_conveyanceCharges || "",
          equipmentDelivery: quotationData?.qt_equipmentDelivery || "",
          courierCharges: quotationData?.qt_courierCharges || "",
          discount: quotationData?.qt_discount || "",
        };

        quotationData && setIsMergedProduct(quotationData?.qt_quantityMerge);
        quotationData && updateInvoiceObject(newData);
        // quotationData && setGrandTotal(quotationData?.qt_totalAmount);

       
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchQutationDetails" ,"Edit Invoice")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const updateQuoObject = (key, value, q) => {
    let newQuoObject = {
      ...invoiceObject,
    };

    newQuoObject[key] = value;

    updateInvoiceObject({ ...newQuoObject });
  };

  const filterProductListByCompany = () => {
    const clientID = invoiceObject?.companyName?.id;
    const filteredList = productList.filter(
      (item) => item.clientId === clientID || item.clientId === 0
    );

    let uniqueproductsByRange = [];
    let rangeValueSet = new Set();
    
    filteredList.forEach((customerProduct) => {
      // Check if rangeValue is not null and not already added to the Set //&& !rangeValueSet.has(customerProduct.rangeValue)
      if (customerProduct.rangeValue !== null ) {
        rangeValueSet.add(customerProduct.rangeValue); // Add to Set to ensure uniqueness
    
        uniqueproductsByRange.push({
          id: customerProduct.id,
          label: `${customerProduct.id}, ${customerProduct.instrumentName}`,
          rate: customerProduct.rate,
          key: customerProduct.id,
          name: customerProduct.instrumentName,
          rangeValue: customerProduct.rangeValue,
        });
      }
    });

    const uniqueList = Object.values(
      filteredList.reduce((map, item) => {
        if (!map[item.instrumentId] || item.clientId === clientID) {
          map[item.instrumentId] = item;
        }
        return map;
      }, {})
    );

    const productsArray = uniqueList.map((customerProduct) => ({
      id: customerProduct.id,
      label: `${customerProduct.id}, ${customerProduct.instrumentName}`,
      rate: customerProduct.rate,
      key: customerProduct.id,
      name: customerProduct.instrumentName,
      rangeValue: customerProduct.rangeValue,
    }));

    setUniqueProductsByRange(uniqueproductsByRange);
    setFilterProductList(productsArray);
  };

  const getProductList = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "select cp.*, ins.instrumentName from instrumentPriceList as cp left join instruments as ins on cp.instrumentId=ins.id;",
    };
    return axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        setProductList(res.data);

        return res.data;
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getProductList" ,"Edit Invoice")}><u>Error: {err.message}</u></h6>);
        }
      });
  };
  const getPoAcceptance = () => {
    let url = BASE_URL;
    const payload = {
      query:
        "SELECT * FROM poAcceptance ",
        //WHERE NOT EXISTS (SELECT 1 FROM customInvoice WHERE customInvoice.poNumber = poAcceptance.poNumber)
    };
    axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        setOpTableData(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getPoAcceptance" ,"Edit Invoice")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const getDataByPartialInvoice = (breakupId) => {
    let filteredProducts = getAllProducts;
  
    if (breakupId !== "Final") {
      filteredProducts = getAllProducts.filter(
        (product) => product.breakupId == breakupId
      );
    }
  
    const processedProducts = isMergedProduct
      ? Object.values(
          filteredProducts.reduce((acc, obj) => {
            const key = obj.rate + "_" + obj.equipmentId;
            acc[key] = acc[key] || { ...obj, quantity: 0 };
            acc[key].quantity += obj.quantity;
            return acc;
          }, {})
        )
      : filteredProducts;
  
    setproductRows(processedProducts);
  };
  

  useEffect(async () => {
    if (params.enquiryId || params.clientId) {
      let Client = clientList?.filter((e) => e.id == params.clientId)[0];

      const addressIds = Client?.address
      const shippingAddressIds = Client?.shippingAddress

     let newAddressArray = await fetchAddresses(addressIds,shippingAddressIds)
      setAllAddress(newAddressArray);

    }
  }, [invoiceObject?.companyName]);

  useEffect(()=>{
    if(params.quotationId && invoiceObject?.id)fetchDataAndProcess(invoiceObject?.id , isMergedProduct);
  },[invoiceObject?.id ,isMergedProduct])

  // console.log("params.quotationId",params.quotationId)

  useEffect(() => {
    getDataByPartialInvoice(state?.breakupId);
  }, [state?.breakupId, getAllProducts,isMergedProduct]);

  useEffect(() => {
    if (invoiceObject?.companyName?.id) getSRFS();
  }, [invoiceObject?.companyName?.id]);

  useEffect(() => {
    filterProductListByCompany();
  }, [invoiceObject?.companyName?.id, productList]);

  useEffect(() => {
    getPoAcceptance();
    getCustomer();
    getProductList();
    fetchSettings();
  }, []);

  useEffect(() => {
    if (params.invoiceId) fetchInvoiceDetails();
    if (params.quotationId) fetchQutationDetails();
    if (params.invoiceId) paymentMasterIDFetch();
  }, [clientList, filterProductList]);

  // console.log({poNumber,opTableData},params.invoiceId,invoiceNumber1)

  return (
    <EditWrap>
      <GetSrfData
        updateQuoObject={updateQuoObject}
        invoiceObject={invoiceObject}
        setproductReadingRows={setproductRows}
        setGetAllSrfProducts={setGetAllSrfProducts}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Create/Update Invoice
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              createInvoiceGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        {/* <Grid item xs={3}>
          <Autocomplete
            disabled={params.invoiceId}
            size="small"
            id="invoice_create_company"
            options={clientList}
            value={invoiceObject.companyName}
            renderInput={(params) => (
              <TextField {...params} label="Client *" />
            )}
            onChange={async (event, value) => {
              const addressIds = value?.address;
              const shippingAddressIds = value?.shippingAddress;

              let newAddressArray = await fetchAddresses(
                addressIds,
                shippingAddressIds
              );
              setAllAddress(newAddressArray);

              clientList.map((client) => {
                updateQuoObject("companyName", value);
              });
            }}
          />
        </Grid> */}

<Grid item xs={12} sm={6} md={3} lg={3}>
      <Autocomplete
        disabled={params.invoiceId}
        size="small"
        id="invoice_create_company"
        options={clientList}
        value={invoiceObject.companyName}
        renderInput={(params) => (
          <TextField {...params} label="Client *" />
        )}
        onChange={async (event, value) => {
          const addressIds = value?.address;
          const shippingAddressIds = value?.shippingAddress;

          // Debounce the fetchAddresses function here
          debouncedFetchAddresses(addressIds, shippingAddressIds);

          clientList.map((client) => {
            updateQuoObject('companyName', value);
          });
        }}
      />
      {loading && <p>Loading...</p>} {/* Display a loading indicator */}
    </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3} id="invoice_create_address">
          {allAddress?.length === 1 ||
          params?.invoiceId ||
          params?.quotationId ? (
            <TextField
              // value={allAddress?.[0] || invoiceObject?.address || ""}
              value={
                allAddress?.length === 1
                  ? allAddress[0]?.address // If allAddress has only one element, use its address
                  : allAddress.find(
                      (e) => e.id == (invoiceObject?.address).split(",")?.[0]
                    )?.address || ""
              }
              label="Billing Address *"
              fullWidth
              variant="outlined"
              size="small"
              disabled
            />
          ) : (
            <Autocomplete
              options={allAddress}
              size="small"
              getOptionLabel={(option) => option?.address}
              onChange={(event, value) => {
                updateQuoObject(
                  "address",
                  `${value?.id},${value?.shipping?.id}`
                );
                setSelectedAddress(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Billing Address *"
                  variant="outlined"
                />
              )}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            value={
              allAddress?.length === 1
                ? allAddress[0]?.shipping?.address // If allAddress has only one element, use its shipping address
                : selectedAddress?.shipping?.address ||
                  allAddress.find(
                    (e) => e.id == (invoiceObject?.address).split(",")?.[0]
                  )?.shipping?.address ||
                  ""
            }
            id="outlined-basic"
            label="Shipping Address *"
            fullWidth
            variant="outlined"
            size="small"
            disabled
          />
        </Grid>

        {/* {!params.clientId && ( */}
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Autocomplete
            disabled={params.quotationId}
            size="small"
            id="invoice_create_srfno"
            value={invoiceObject?.selectedSRF.filter((item) => item !== "")}
            options={srfNumbers}
            renderInput={(params) => (
              <TextField {...params} label="SRF Number " />
            )}
            multiple
            onChange={(event, value) => {
              updateQuoObject("selectedSRF", value);
            }}
          />
        </Grid>
        {/* )} */}

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="invoice_create_referenceNumber"
            label="Reference Number"
            inputProps={{
              autoComplete: 'off',
            }}
            size="small"
            value={invoiceObject.referenceNumber || ""}
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("referenceNumber", e.target.value);
            }}
          />
        </Grid>

        {params.quotationId && (
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
              id="outlined-basic"
              label="Quotation Number *"
              size="small"
              value={invoiceObject.quotationNumber || ""}
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="invoice_create_custChallanNo"
            label="Customer Challan Number"
            size="small"
            fullWidth
            inputProps={{
              autoComplete: 'off',
            }}
            variant="outlined"
            value={invoiceObject.challanNo}
            onChange={(e) => {
              updateQuoObject("challanNo", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3} id="invoice_create_customerchallanDate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Customer Challan Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                invoiceObject.challanDate
                  ? new Date(invoiceObject.challanDate)
                  : ""
              }
              onChange={(newValue) => {
                updateQuoObject("challanDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="invoice_create_ourChallanNo"
            label="Our Challan Number"
            size="small"
            fullWidth
            inputProps={{
              autoComplete: 'off',
            }}
            variant="outlined"
            value={invoiceObject.ourChallanNo}
            onChange={(e) => {
              updateQuoObject("ourChallanNo", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3} id="invoice_create_ourchallanDate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Our Challan Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                invoiceObject.ourChallanDate
                  ? new Date(invoiceObject.ourChallanDate)
                  : ""
              }
              onChange={(newValue) => {
                updateQuoObject("ourChallanDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="invoice_create_invoicenumber"
            label="Invoice Number *"
            size="small"
            disabled
            fullWidth
            variant="outlined"
            // value={invoiceObject.invoiceNumber}
            value={invoiceNumber1 || ""}
            onChange={(e) => {
              // updateQuoObject("invoiceNumber", e.target.value);
              setInvoiceNumber1(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3} id="invoice_create_invoiceDate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Invoice Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                invoiceObject.invoiceDate
                  ? new Date(invoiceObject.invoiceDate)
                  : ""
              }
              onChange={(newValue) => {
                updateQuoObject("invoiceDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="invoice_create_subject"
            label="Subject *"
            size="small"
            value={invoiceObject.subject || ""}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("subject", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="invoice_create_termsConditions"
            label="Terms Conditions *"
            InputLabelProps={{ shrink: true }}
            fullWidth
            rows={2}
            multiline
            value={
              invoiceObject.termsConditions
                ? invoiceObject.termsConditions
                : defaultReamrk
            }
            variant="outlined"
            maxRows={5}
            onChange={(e) => {
              updateQuoObject("termsConditions", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Autocomplete
            size="small"
            id="invoice_create_gstType"
            value={invoiceObject?.gstType || gstTypes[0]}
            options={gstTypes}
            renderInput={(params) => (
              <TextField {...params} label="GST Type *" />
            )}
            onChange={(event, val) => {
              updateQuoObject("gstType", val);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Autocomplete
            size="small"
            id="invoice_create_gstPercentage"
            value={
              gstPercentageOptions.find(
                (val) => val.value === invoiceObject?.GSTPercentage
              ) || null
            }
            options={gstPercentageOptions}
            getOptionLabel={(option) => `${option.label} %`}
            renderInput={(params) => (
              <TextField {...params} label="GST Percentage" />
            )}
            onChange={(event, val) => {
              updateQuoObject("GSTPercentage", val?.value);
            }}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6} md={3} lg={3} id="invoice_create_poNumber"> */}
          {/* {params.poNumber ? (
            <TextField
              size="small"
              label="PO Number"
              value={params.poNumber}
              disabled
            />
          ) : ( */}
            {/* <Autocomplete
              size="small"
              id="combo-box-demo"
              options={opTableData}
              getOptionLabel={(option) => option?.poNumber?.toString()}
              value={
                opTableData?.find((ele) => ele?.poNumber == poNumber) || null
              }
              renderInput={(params) => (
                <TextField {...params} label="PO Number" />
              )}
              onChange={(event, newValue) => {
                if (!newValue) {
                  setpoNumber("");
                  setpoDate("");
                  return;
                }

                const selectedPo = opTableData.find(
                  (po) => po.poNumber === newValue.poNumber
                );
                if (selectedPo) {
                  setpoNumber(selectedPo.poNumber);
                  setpoDate(selectedPo.poDate);
                } else {
                  setpoNumber("");
                  setpoDate("");
                }
              }}
            /> */}
          {/* )} */}
        {/* </Grid> */}

        {/* <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="invoice_create_poDate"
            label="PO Date"
            size="small"
            disabled
            fullWidth
            variant="outlined"
            value={moment(poDate).format("YYYY-MM-DD") || ""}
            onChange={(e) =>
              setpoDate(moment(e.target.value).format("YYYY-MM-DD"))
            }
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="invoice_create_poDate"
            label="PO Number"
            size="small"
            fullWidth
            variant="outlined"
            value={poNumber || ""}
            onChange={(e) =>
              setpoNumber(e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="PO Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                poDate
                  ? new Date(poDate)
                  : ""
              }
              onChange={(newValue) => {
                setpoDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="invoice_create_equipmentDelivery"
            label="Equipment Delivery"
            size="small"
            value={invoiceObject.equipmentDelivery || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("equipmentDelivery", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} >
          <FormControlLabel
            control={
              <Checkbox
                checked={isMergedProduct  == 1}
                onChange={(e) => {
                  setIsMergedProduct(e.target.checked ? 1 : 0);
                }}
                color="primary"
              />
            }
            label={
              <Typography variant="body1" >With Merged Product Quantity  </Typography>
            }
          />
        </Grid>
      </Grid>
      <div>
        <ProductDetails
          productArray={filterProductList}
          uniqueProductsByRange={uniqueProductsByRange}
          setproductReadingRows={setproductRows}
          productReadingRows={productRows}
          state={state}
          setState={setState}
          updateQuoObject={updateQuoObject}
          grandTotal={grandTotal}
          setGrandTotal={setGrandTotal}
          invoiceObject={invoiceObject}
          getAllProducts={getAllProducts}
          getAllSrfsProducts={getAllSrfsProducts}
          isMergedProduct={isMergedProduct}
          isInvoice={true}
        />
      </div>
      <Toolbar style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem", }}>
      <Button
          variant="contained"
          style={{backgroundColor:"grey"}}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        {userType !== "3" ? (
          <Button
            id="invoice_create_save"
            variant="contained"
            size="small"
            disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
            sx={{ m: 0 }}
            onClick={() => {
              if (
                invoiceObject.gstType?.value !== null &&
                invoiceObject.companyName &&
                invoiceNumber1 &&
                productRows.length > 0
              ) {
                handleSubmit();
              } else {
                if (!invoiceObject.gstType?.value) {
                  toast.error("Please select GST Type!");
                }
                if (!invoiceObject.companyName) {
                  toast.error("Please Select Client!");
                }
                if (!invoiceNumber1) {
                  toast.error("Please enter Invoice Number!");
                }
                if (!productRows.length) {
                  toast.error("Please Add Product and Quantity!");
                }
              }
            }}
          >
            {params?.invoiceId ? "Update" : "Save"}
          </Button>
        ) : (
          console.log("")
        )}
      </Toolbar>
      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </EditWrap>
  );
}
